<template>
    <frontoffice-layout>
        <section class="min-h-screen--with-header bg-blue-gray-200 py-4 flex items-center bg-cover mask" :style="`background-image:url(${require('@/assets/images/home-bg.jpeg')})`">
            <section class="md:container md:mx-auto <md:px-4 w-full">
                <div class="relative max-w-142 mx-auto text-center text-white">
                    <header class="mb-3">
                        <v-heading level="h1"> {{ $t('action.book_now') }} </v-heading>
                    </header>

                    {{ $t('home.intro.text') }}
                    
                    <span v-if="main_contact"> {{ $t('home.intro.contact') }} </span>

                    <footer class="flex items-center justify-center mt-8 <md:flex-col">
                        <v-button v-if="main_contact" :href="`https://wa.me/${$trim(main_contact.mobile)}`" target="_blank" icon="whatsapp" variant="primary" class="mx-2 w-full <md:mb-2">
                            {{ main_contact.mobile }}
                        </v-button>

                        <v-button variant="primary" :class="{'mx-2 w-full': main_contact}" :to="{ name: 'booking' }">
                            {{ $t('action.book') }}
                        </v-button>
                    </footer>
                </div>
            </section>
        </section>
    </frontoffice-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    },

    computed: {
        ...mapGetters('auth', [
            'main_contact'
		]),
    }
};
</script>

<style lang="scss" scoped>
.mask {
    position: relative;
    
    &:before {
        content: "";
        @apply bg-blue-gray-800 bg-opacity-50 absolute w-full h-full left-0 top-0;
    }
}
</style>
